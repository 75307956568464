<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidación: {{ id }}</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/tep">Tep</router-link>
                  </li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <!-- Tarjeta de vista del formulario -->
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Formulario {{ accion }} Liquidaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="empresa">Empresa</label>
                      <v-select
                        :class="[
                          $v.form.empresa_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="empresa"
                        placeholder="Seleccione Empresa..."
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="empresa">Contratos</label>
                      <v-select
                        :class="[
                          $v.contratos.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="contratos"
                        placeholder="Seleccione contratos..."
                        label="descripcion"
                        class="form-control form-control-sm p-0"
                        multiple
                        :options="listasForms.contratos"
                        @input="selectContrato()"
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_ini">Fecha Inicio</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_ini"
                        :class="
                          $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="getNombre()"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_fin">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_fin"
                        :class="
                          $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="getNombre()"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="estado">Estado</label>
                      <select
                        id="estado"
                        v-model="form.estado"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          :disabled="
                            estado.numeracion === 2 &&
                              $route.params.accion == 'Crear'
                          "
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="area">Tipo Vehiculo</label>
                      <v-select
                        :class="[
                          $v.form.tipos_vehiculo.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.tipos_vehiculo"
                        placeholder="Seleccione Tipo Vehiculo..."
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.tipos_vehiculo"
                        multiple
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="sitio">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="obs">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm"
                        id="obs"
                        cols="20"
                        rows="2"
                        v-model="form.observaciones"
                        :disabled="estadodis"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label for="iva">Incluye IVA</label>
                      <input
                        type="checkbox"
                        v-model="form.incluye_iva"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn bg-primary col-md-1"
                  v-if="
                    !$v.form.$invalid &&
                      !$v.contratos.$invalid &&
                      $store.getters.can('tep.liquidacionesNacionales.create')
                  "
                  @click="save()"
                  :disabled="estadodis"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
            <!-- Vista de los detalles de la liquidación generada -->
            <div class="card" v-if="id">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Resumen"
                    data-toggle="tab"
                    href="#Resumen"
                    >Resumen</a
                  >
                </li>
                <!-- <li
                  class="nav-item"
                  v-if="
                    detalleFacturacion !== null && detalleFacturacion.length > 0
                  "
                >
                  <a
                    class="nav-link"
                    id="tab-Detalle"
                    data-toggle="tab"
                    href="#Detalle"
                    v-if="
                      detalleFacturacion !== null &&
                      detalleFacturacion.length > 0
                    "
                    >Detalle</a
                  >
                </li> -->
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Resumen">
                  <div class="card-body table-responsive pb-0">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                            text-nowrap
                          "
                          style="font-size: 0.85em"
                        >
                          <thead>
                            <tr>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.vehiculo"
                                  class="form-control form-control-sm"
                                  placeholder="Vehiculo"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.itinerario"
                                  class="form-control form-control-sm"
                                  placeholder="# Servicio"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.solicitud"
                                  class="form-control form-control-sm"
                                  placeholder="# Solicitud"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.cond"
                                  class="form-control form-control-sm"
                                  placeholder="Documento Conductor"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.tv"
                                  class="form-control form-control-sm"
                                  placeholder="Tipo Vehiculo"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.fecha_ini"
                                  class="form-control form-control-sm"
                                  placeholder="Inicio Serv."
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.fecha_fin"
                                  class="form-control form-control-sm"
                                  placeholder="Fin Serv."
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.dias_servicio"
                                  class="form-control form-control-sm"
                                  placeholder="Días de Serv."
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.origen"
                                  class="form-control form-control-sm"
                                  placeholder="Origen"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.destino"
                                  class="form-control form-control-sm"
                                  placeholder="Destino"
                                />
                              </th>
                              <th rowspan="2">Responsables</th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.cant_pasajeros"
                                  class="form-control form-control-sm"
                                  placeholder="# Pasajeros"
                                />
                              </th>
                              <th rowspan="2">Tarifa</th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.cecoafe"
                                  class="form-control form-control-sm"
                                  placeholder="Ceco-AFE"
                                />
                              </th>
                              <th rowspan="2">
                                <input
                                  v-model="buscarDet.porcentaje"
                                  class="form-control form-control-sm"
                                  placeholder="% Distribución"
                                />
                              </th>
                              <th colspan="2">Horas Add</th>
                              <th colspan="2">KM Add</th>
                              <th colspan="2">Pernoctes</th>
                              <th colspan="2">Peajes</th>
                              <th rowspan="2">Tarifa Base</th>
                              <th rowspan="2">Subtotal</th>
                              <th rowspan="2">Costo Tot.</th>
                            </tr>
                            <tr>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                              <th>Cant</th>
                              <th>Costo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(liqui, index) in filteredRowsDetail"
                              :key="index"
                            >
                              <td class="text-center pl-5 pr-5">
                                <button
                                  type="button"
                                  class="btn btn-dark p-0 w-100"
                                  data-toggle="modal"
                                  data-target="#modal_detalle"
                                  style="font-size: 10px !important"
                                  @click="getDetalleVehis(liqui)"
                                >
                                  {{
                                    liqui.tep_preliquidacion_nacional.tep_viaje
                                      .vehiculo.placa
                                  }}
                                </button>
                              </td>
                              <td class="text-center pl-5 pr-5">
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje.id
                                }}
                              </td>
                              <td class="text-center pl-5 pr-5">
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .itinerario.tep_solicitud_id
                                }}
                              </td>
                              <td>
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .conductor.nombres
                                }}
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .conductor.apellidos
                                }}
                                <span class="badge badge-info">
                                  {{
                                    liqui.tep_preliquidacion_nacional.tep_viaje
                                      .conductor.numero_documento
                                  }}
                                </span>
                              </td>
                              <td>
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .tipo_vehiculo.nombre
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .fecha_ini
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .fecha_fin
                                }}
                              </td>
                              <td class="text-center pl-5 pr-5">
                                {{
                                  restaFechas(
                                    liqui.tep_preliquidacion_nacional.tep_viaje
                                      .fecha_ini,
                                    liqui.tep_preliquidacion_nacional.tep_viaje
                                      .fecha_fin
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .direccion_origen
                                }}
                              </td>
                              <td>
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .direccion_destino
                                }}
                              </td>
                              <td>
                                <div
                                  v-for="(user, index) in liqui
                                    .tep_preliquidacion_nacional.tep_viaje
                                    .solicitudes
                                    ? liqui.tep_preliquidacion_nacional
                                        .tep_viaje.solicitudes.users
                                    : null"
                                  :key="index"
                                >
                                  {{ user.name }}
                                </div>
                              </td>
                              <td class="text-center pl-5 pr-5">
                                {{
                                  liqui.tep_preliquidacion_nacional.tep_viaje
                                    .itinerario.pasajeros.length
                                }}
                              </td>
                              <td>
                                {{
                                  liqui.tep_preliquidacion_nacional
                                    .det_tarifa_nacional.numero_tarifa
                                    ? `(${liqui.tep_preliquidacion_nacional.det_tarifa_nacional.numero_tarifa})`
                                    : ""
                                }}
                                {{
                                  liqui.tep_preliquidacion_nacional
                                    .det_tarifa_nacional.nombre_tarifa
                                }}
                              </td>
                              <td class="pr-5">{{ liqui.ceco_afe }}</td>
                              <td class="text-right">
                                {{ liqui.porcentaje }}%
                              </td>
                              <td class="text-right pl-4">
                                {{
                                  (
                                    liqui.cant_hora_add *
                                    (parseFloat(liqui.porcentaje) / 100)
                                  ).toFixed(2)
                                }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoHoraAdd }}
                              </td>
                              <td class="text-right pl-4">
                                {{
                                  (
                                    liqui.cant_km_add *
                                    (parseFloat(liqui.porcentaje) / 100)
                                  ).toFixed(2)
                                }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoKmAdd }}
                              </td>
                              <td class="text-right pl-4">
                                {{
                                  (
                                    liqui.cant_pernocte *
                                    (parseFloat(liqui.porcentaje) / 100)
                                  ).toFixed(2)
                                }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoPernocte }}
                              </td>
                              <td class="text-right pl-4">
                                {{
                                  (
                                    liqui.cant_peaje *
                                    (parseFloat(liqui.porcentaje) / 100)
                                  ).toFixed(2)
                                }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoPeaje }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoTarifaBase }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoSubtotal }}
                              </td>
                              <td class="text-right pl-4">
                                {{ liqui.costoTotal }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="15"
                                class="table-warning text-right font-weight-bold"
                              >
                                TOTALES:
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.horasAdd_cant.toFixed(2) }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.horasAdd_cost }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.kmAdd_cant.toFixed(2) }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.kmAdd_cost }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.pernoctes_cant.toFixed(2) }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.pernoctes_cost }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.peajes_cant.toFixed(2) }}
                              </td>
                              <td
                                class="text-right table-warning font-weight-bold"
                              >
                                {{ totalesFilter.peajes_cost }}
                              </td>
                              <td
                                colspan="2"
                                class="text-right table-success font-weight-bold"
                              >
                                TOTAL:
                              </td>
                              <td
                                class="text-right table-success font-weight-bold"
                              >
                                {{ totalesFilter.costo_total }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Vista de los detalles de las firmas de la liquidación generada -->
            <div
              class="card"
              v-if="
                $route.params.data_edit &&
                  $route.params.data_edit.tep_liquidaciones_nacionales_firmas
                    .length > 0
              "
            >
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Aprobaciones...</h5>
              </div>
              <div class="card-body">
                <div class="row justify-content-md-center">
                  <div
                    class="col-md-4"
                    v-for="firma in $route.params.data_edit
                      .tep_liquidaciones_nacionales_firmas"
                    :key="firma.id"
                  >
                    <div class="card bg-light d-flex flex-fill">
                      <div class="card-header text-muted border-bottom-0">
                        <h5>
                          {{
                            firma.tipo_firma == 1
                              ? "Firma Administrador del Contrato"
                              : firma.tipo_firma == 2
                              ? "Firma Contratista"
                              : firma.tipo_firma == 3
                              ? "Firma Interventor del Contrato"
                              : ""
                          }}
                        </h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <p>
                              Firmado por: <br /><strong>{{
                                firma.user.name
                              }}</strong>
                            </p>
                            <p class="text-center mr-5 ml-5">
                              <img
                                :src="uri_docs + firma.user.link_firma"
                                class="img-fluid"
                              />
                            </p>
                            <p>
                              Fecha: <br /><strong>{{
                                firma.fecha_firma
                              }}</strong>
                            </p>
                            <p>
                              Observación: <br />
                              {{ firma.observacion }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div
      class="modal fade"
      id="modal_detalle"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-keyboard="false"
      v-if="modalData"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Detalle del viaje -->
          <div class="modal-body" style="font-size: 0.85em">
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative p-3 m-2 bg-light">
                  <div class="ribbon-wrapper ribbon-lg">
                    <div class="ribbon bg-dark">Viaje</div>
                  </div>
                  <div class="row">
                    <div class="col-sm-1">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje.id
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Viaje</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .direccion_origen
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Origen</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .direccion_destino
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Destino</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .transportadora.razon_social
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Empresa</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .fecha_ini
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Fecha Inicio</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .fecha_fin
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Fecha Fin</span
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="description-block">
                        <h5
                          class="description-header"
                          :class="
                            modalData.tep_preliquidacion_nacional.tep_viaje.ruta
                              ? ''
                              : 'text-muted'
                          "
                        >
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje.ruta
                              ? modalData.tep_preliquidacion_nacional.tep_viaje
                                  .ruta.nombre
                              : "Sin Ruta"
                          }}
                        </h5>
                        <span class="description-text text-secondary"
                          >Ruta</span
                        >
                      </div>
                    </div>
                    <div
                      class="col-sm-2"
                      v-if="
                        $store.getters.can(
                          'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                        )
                      "
                    >
                      <div class="description-block">
                        <h5 class="description-header">
                          {{
                            modalData.tep_preliquidacion_nacional
                              .conc_horas_trabajadas
                          }}
                        </h5>
                        <span class="description-text text-secondary">
                          Horas Trabajadas
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Detalle de la liquidación -->
          <div class="modal-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                Liquidación -
                <strong>{{
                  modalData.tep_preliquidacion_nacional.tep_viaje.vehiculo.placa
                }}</strong>
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                    text-nowrap
                  "
                  style="font-size: 0.85em"
                >
                  <thead>
                    <tr>
                      <th rowspan="2"># Servicio</th>
                      <th rowspan="2"># Solicitud</th>
                      <th rowspan="2">Conductor</th>
                      <th rowspan="2">Tipo Vehículo</th>
                      <th rowspan="2">Ceco-AFE</th>
                      <th rowspan="2">% Distribución</th>
                      <th rowspan="2">Inicio Serv.</th>
                      <th rowspan="2">Fin Serv.</th>
                      <th rowspan="2">Días de Serv.</th>
                      <th rowspan="2">Origen</th>
                      <th rowspan="2">Destino</th>
                      <th rowspan="2">Responsables</th>
                      <th rowspan="2"># Pasajeros</th>
                      <th colspan="2">Horas Add</th>
                      <th colspan="2">KM Add</th>
                      <th colspan="2">Pernoctes</th>
                      <th colspan="2">Peajes</th>
                      <th rowspan="2">Tarifa Base</th>
                      <th rowspan="2">Subtotal</th>
                      <th rowspan="2">Costo Tot.</th>
                    </tr>
                    <tr>
                      <th>Cant</th>
                      <th>Costo</th>
                      <th>Cant</th>
                      <th>Costo</th>
                      <th>Cant</th>
                      <th>Costo</th>
                      <th>Cant</th>
                      <th>Costo</th>
                    </tr>
                  </thead>
                  <tbody v-if="modalData.tep_preliquidacion_nacional">
                    <tr>
                      <td class="text-center pl-5 pr-5">
                        {{ modalData.tep_preliquidacion_nacional.tep_viaje.id }}
                      </td>
                      <td class="text-center pl-5 pr-5">
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .itinerario.tep_solicitud_id
                        }}
                      </td>
                      <td>
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .conductor.nombres
                        }}
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .conductor.apellidos
                        }}
                        <span class="badge badge-info">
                          {{
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .conductor.numero_documento
                          }}
                        </span>
                      </td>
                      <td>
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .tipo_vehiculo.nombre
                        }}
                      </td>
                      <td class="pr-5">{{ modalData.ceco_afe }}</td>
                      <td class="text-right">{{ modalData.porcentaje }}%</td>
                      <td class="text-center">
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .fecha_ini
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .fecha_fin
                        }}
                      </td>
                      <td class="text-center pl-5 pr-5">
                        {{
                          restaFechas(
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .fecha_ini,
                            modalData.tep_preliquidacion_nacional.tep_viaje
                              .fecha_fin
                          )
                        }}
                      </td>
                      <td>
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .direccion_origen
                        }}
                      </td>
                      <td>
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .direccion_destino
                        }}
                      </td>
                      <td>
                        <div
                          v-for="(user, index) in modalData
                            .tep_preliquidacion_nacional.tep_viaje.solicitudes
                            ? modalData.tep_preliquidacion_nacional.tep_viaje
                                .solicitudes.users
                            : null"
                          :key="index"
                        >
                          {{ user.name }}
                        </div>
                      </td>
                      <td class="text-center pl-5 pr-5">
                        {{
                          modalData.tep_preliquidacion_nacional.tep_viaje
                            .itinerario.pasajeros.length
                        }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.cant_hora_add }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoHoraAdd }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.cant_km_add }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoKmAdd }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.cant_pernocte }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoPernocte }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.cant_peaje }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoPeaje }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoTarifaBase }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoSubtotal }}
                      </td>
                      <td class="text-right pl-4">
                        {{ modalData.costoTotal }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "TepLiquidacionNacionalesForm",
  components: {
    vSelect,
    Loading,
  },

  data() {
    return {
      id: null,
      accion: "",
      metodo: "",
      firma_admin: 0,
      cargando: false,
      estadodis: false,
      sitio: {},
      empresa: {},
      contratos: [],
      tipo_modal: 1,
      modalData: null,
      form: {
        nombre: null,
        empresa_id: null,
        tipos_vehiculo: [],
        fecha_ini: "",
        fecha_fin: "",
        estado: null,
        incluye_iva: false,
      },
      liquidaciones: {},
      liquiDetalleVehis: [],
      detalleLiqui: [],
      chk_acumulan_km: [],
      detalleFacturacion: null,
      total_porcentaje: 0,
      listasForms: {
        empresas: [],
        contratos: [],
        estados: [],
        operaciones: [],
        areas: [],
        tipos_vehiculo: [],
        respon: [],
      },
      totales: {
        turnos_cant: 0,
        turnos_cost: 0,
        horasAdd_cant: 0,
        horasAdd_cost: 0,
        kmAdd_cant: 0,
        kmAdd_cost: 0,
        pernoctes_cant: 0,
        pernoctes_cost: 0,
        costo_total: 0,
      },
      totalesVh: {
        turnos_cant: 0,
        turnos_cost: 0,
        horasAdd_cant: 0,
        horasAdd_cost: 0,
        kmAdd_cant: 0,
        kmAdd_cost: 0,
        pernoctes_cant: 0,
        pernoctes_cost: 0,
        costo_total: 0,
      },
      formActualizarCA: false,
      idActualizarCA: -1,
      formCeAf: {
        responsable: null,
        tipo_presupuesto: null,
        porcentaje: null,
        numero: null,
      },
      formCeAf2: {
        responsable: null,
        tipo_presupuesto: null,
        porcentaje: null,
        numero: null,
      },
      form1: {},
      cecosAfes: [],
      buscarDet: {
        itinerario: "",
        solicitud: "",
        vehiculo: "",
        cond: "",
        tv: "",
        cecoafe: "",
        porcentaje: "",
        fecha_ini: "",
        fecha_fin: "",
        dias_servicio: "",
        origen: "",
        destino: "",
        cant_pasajeros: "",
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      empresa_id: {
        required,
      },
      estado: {
        required,
      },
      tipos_vehiculo: {
        required,
      },
    },
    formCeAf: {
      responsable: { required },
      tipo_presupuesto: { required },
      porcentaje: { required },
      numero: { required },
    },
    formCeAf2: {
      responsable: { required },
      tipo_presupuesto: { required },
      porcentaje: { required },
      numero: { required },
    },
    contratos: {
      required,
    },
  },

  computed: {
    filteredRowsDetail() {
      if (this.detalleLiqui) {
        return this.detalleLiqui.filter((det) => {
          det.costoHoraAdd = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            (det.costo_hora_add * parseFloat(det.porcentaje)) / 100
          );
          det.costoKmAdd = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            (det.costo_km_add * parseFloat(det.porcentaje)) / 100
          );
          det.costoPeaje = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            (det.costo_peaje * parseFloat(det.porcentaje)) / 100
          );
          det.costoPernocte = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            (det.costo_prenocte * parseFloat(det.porcentaje)) / 100
          );
          det.costoSubtotal = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_subtotal
          );
          det.costoTarifaBase = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_tarifa_base
          );
          det.costoTotal = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            det.costo_total
          );

          const vh = det.tep_preliquidacion_nacional.tep_viaje.vehiculo
            ? det.tep_preliquidacion_nacional.tep_viaje.vehiculo.placa.toLowerCase()
            : null;
          const itin = det.tep_preliquidacion_nacional.tep_viaje.itinerario.id
            .toString()
            .toLowerCase();
          const solic = det.tep_preliquidacion_nacional.tep_viaje.itinerario
            .tep_solicitud_id
            ? det.tep_preliquidacion_nacional.tep_viaje.itinerario.tep_solicitud_id
                .toString()
                .toLowerCase()
            : "";
          const cond = det.tep_preliquidacion_nacional.tep_viaje.conductor
            ? det.tep_preliquidacion_nacional.tep_viaje.conductor.numero_documento.toLowerCase()
            : "";
          const tv = det.tep_preliquidacion_nacional.tep_viaje.tipo_vehiculo.nombre.toLowerCase();
          const ca = det.ceco_afe.toLowerCase();
          const prc = det.porcentaje.toLowerCase();
          const fini = det.tep_preliquidacion_nacional.tep_viaje.fecha_ini
            .toString()
            .toLowerCase();
          const ffin = det.tep_preliquidacion_nacional.tep_viaje.fecha_fin
            .toString()
            .toLowerCase();
          const dserv = this.restaFechas(
            det.tep_preliquidacion_nacional.tep_viaje.fecha_ini,
            det.tep_preliquidacion_nacional.tep_viaje.fecha_fin
          )
            .toString()
            .toLowerCase();
          const orig = det.tep_preliquidacion_nacional.tep_viaje
            .direccion_origen
            ? det.tep_preliquidacion_nacional.tep_viaje.direccion_origen.toLowerCase()
            : "";
          const dest = det.tep_preliquidacion_nacional.tep_viaje
            .direccion_destino
            ? det.tep_preliquidacion_nacional.tep_viaje.direccion_destino.toLowerCase()
            : "";
          const cpasj = det.tep_preliquidacion_nacional.tep_viaje.itinerario.pasajeros.length
            .toString()
            .toLowerCase();

          const searchTermvh = this.buscarDet.vehiculo.toLowerCase();
          const searchTermitin = this.buscarDet.itinerario.toLowerCase();
          const searchTermsolic = this.buscarDet.solicitud.toLowerCase();
          const searchTermcond = this.buscarDet.cond.toLowerCase();
          const searchTermtv = this.buscarDet.tv.toLowerCase();
          const searchTermca = this.buscarDet.cecoafe.toLowerCase();
          const searchTermprc = this.buscarDet.porcentaje.toLowerCase();
          const searchTermfini = this.buscarDet.fecha_ini.toLowerCase();
          const searchTermffin = this.buscarDet.fecha_fin.toLowerCase();
          const searchTermdserv = this.buscarDet.dias_servicio.toLowerCase();
          const searchTermforig = this.buscarDet.origen.toLowerCase();
          const searchTermfdest = this.buscarDet.destino.toLowerCase();
          const searchTermfcpasj = this.buscarDet.cant_pasajeros.toLowerCase();

          return (
            vh.includes(searchTermvh) &&
            itin.includes(searchTermitin) &&
            solic.includes(searchTermsolic) &&
            cond.includes(searchTermcond) &&
            tv.includes(searchTermtv) &&
            ca.includes(searchTermca) &&
            prc.includes(searchTermprc) &&
            fini.includes(searchTermfini) &&
            ffin.includes(searchTermffin) &&
            dserv.includes(searchTermdserv) &&
            orig.includes(searchTermforig) &&
            dest.includes(searchTermfdest) &&
            cpasj.includes(searchTermfcpasj)
          );
        });
      } else {
        return this.detalleLiqui;
      }
    },
    totalesFilter() {
      let totales = {
        horasAdd_cant: 0,
        horasAdd_cost: 0,
        kmAdd_cant: 0,
        kmAdd_cost: 0,
        pernoctes_cant: 0,
        pernoctes_cost: 0,
        peajes_cant: 0,
        peajes_cost: 0,
        costo_total: 0,
      };
      if (this.filteredRowsDetail) {
        let matrix = this.filteredRowsDetail;
        let tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_hora_add"]
              ? matrix[i]["cant_hora_add"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.horasAdd_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_hora_add"]
              ? matrix[i]["costo_hora_add"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.horasAdd_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_km_add"]
              ? matrix[i]["cant_km_add"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.kmAdd_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_km_add"]
              ? matrix[i]["costo_km_add"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.kmAdd_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_peaje"]
              ? matrix[i]["cant_peaje"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.peajes_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_peaje"]
              ? matrix[i]["costo_peaje"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.peajes_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["cant_pernocte"]
              ? matrix[i]["cant_pernocte"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.pernoctes_cant = tot;

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_prenocte"]
              ? matrix[i]["costo_prenocte"] *
                  (parseFloat(matrix[i]["porcentaje"]) / 100)
              : 0
          );
        }
        totales.pernoctes_cost = this.formatCurrency("es-CO", "COP", 2, tot);

        tot = 0;
        for (let i = 0; i < matrix.length; i++) {
          tot += parseFloat(
            matrix[i]["costo_total"] ? matrix[i]["costo_total"] : 0
          );
        }
        totales.costo_total = this.formatCurrency("es-CO", "COP", 2, tot);
      }
      return totales;
    },
  },

  methods: {
    async init() {
      await this.buscarEmpresas();
      await this.getEstados();
      await this.getAreas();
      await this.getTiposVehiculo();

      this.modalData = null;

      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;

      if (this.accion == "Editar") {
        const data_edit = this.$route.params.data_edit;
        this.estadodis =
          data_edit.estado == 2
            ? true
            : !(await this.$store.getters.can(
                "tep.liquidacionesNacionales.edit"
              ));

        /***** se llena los datos del formulario */
        this.form.id = data_edit.id;
        this.form.empresa_id = data_edit.empresa_id;
        this.form.fecha_ini = data_edit.fecha_ini;
        this.form.fecha_fin = data_edit.fecha_fin;
        this.form.estado = data_edit.estado;
        this.form.nombre = data_edit.nombre;
        this.form.incluye_iva = data_edit.incluye_iva;
        await this.getContrato(data_edit.empresa_id);
        let contratosIds = data_edit.contratos.split(",");
        this.contratos = contratosIds
          ? contratosIds.map((id) =>
              this.listasForms.contratos.find((item) => item.id == id)
            )
          : {};

        // Se c1arga los datos de la empresa
        let find_emp = await this.listasForms.empresas.find(
          (e) => e.id === this.form.empresa_id
        );
        this.empresa = find_emp;

        // Se carga los datos de los tipos de vehículo
        let listtv = data_edit.det_liquidaciones_nacionales;
        if (listtv) {
          this.form.tipos_vehiculo = [];
          await listtv.forEach((t) => {
            const tipo_vehiculo =
              t.tep_preliquidacion_nacional.tep_viaje.tipo_vehiculo;
            let find_tv = this.form.tipos_vehiculo.find(
              (tv) => tv.id === tipo_vehiculo.id
            );
            if (find_tv === undefined) {
              this.form.tipos_vehiculo.push({
                id: tipo_vehiculo.id,
                nombre: tipo_vehiculo.nombre,
                cantidad_pasajeros: tipo_vehiculo.cantidad_pasajeros,
              });
            }
          });
        }
        /***** fin - se llena los datos del formulario */

        await this.getTotales();
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    async getNombre() {
      await this.validaFechas();
      if (this.form.empresa_id && this.form.fecha_ini && this.form.fecha_fin) {
        const nombre = `(${this.form.fecha_ini} - ${this.form.fecha_fin}) ${this.empresa.razon_social} `;
        this.form.nombre = nombre;
      }
    },

    async buscarEmpresas() {
      await axios
        .get("api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async selectEmpresa() {
      this.form.empresa_id = null;
      this.contratos = [];
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
        await this.getContrato(this.empresa.id);
      }
      this.getNombre();
    },

    async getContrato(empresa_id) {
      let me = this;
      await axios
        .get("/api/tep/contratosNacionales/lista", {
          params: {
            empresa_id: empresa_id,
          },
        })
        .then((response) => {
          me.listasForms.contratos = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/124").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getResponsable() {
      axios.get("/api/admin/funcionarios/lista").then((response) => {
        this.listasForms.respon = response.data;
      });
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    async getTiposVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTotales() {
      this.detalleLiqui = this.$route.params.data_edit.det_liquidaciones_nacionales;

      this.totales.horasAdd_cant = this.sumColumn(
        this.detalleLiqui,
        "cant_hora_add"
      );
      this.totales.horasAdd_cost = this.sumColumn(
        this.detalleLiqui,
        "costo_hora_add"
      );
      this.totales.kmAdd_cant = this.sumColumn(
        this.detalleLiqui,
        "cant_km_add"
      );
      this.totales.kmAdd_cost = this.sumColumn(
        this.detalleLiqui,
        "costo_km_add"
      );
      this.totales.pernoctes_cant = this.sumColumn(
        this.detalleLiqui,
        "cant_pernocte"
      );
      this.totales.pernoctes_cost = this.sumColumn(
        this.detalleLiqui,
        "costo_prenocte"
      );
      this.totales.peajes_cant = this.sumColumn(
        this.detalleLiqui,
        "cant_peaje"
      );
      this.totales.peajes_cost = this.sumColumn(
        this.detalleLiqui,
        "costo_peaje"
      );
      this.totales.tarif_base = this.sumColumn(
        this.detalleLiqui,
        "costo_tarifa_base"
      );
      this.totales.costo_total = this.sumColumn(
        this.detalleLiqui,
        "costo_total"
      );
    },

    async getDetalleVehis(data) {
      this.modalData = data;
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async save() {
      if (!this.$v.form.$invalid && !this.$v.contratos.$invalid) {
        this.cargando = true;
        this.form.contratos = this.contratos
          .map((contrato) => contrato.id)
          .join(",");
        await axios({
          method: this.metodo,
          url: "/api/tep/liquidacionesNacionales",
          data: this.form,
        })
          .then(async (response) => {
            if (response.data.error !== undefined) {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: response.data.error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              (this.$route.params.id = response.data.id),
                (this.$route.params.accion = "Editar");
              this.$route.params.data_edit = response.data;
              this.init();
              this.$swal({
                icon: "success",
                title: "Se actualizó la liquidación correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              if (this.metodo == "PUT") {
                this.back();
              }
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getCol(matrix, col) {
      var column = [];
      for (var i = 0; i < matrix.length; i++) {
        column.push(matrix[i][col]);
      }
      return column;
    },

    sumColumn(matrix, col) {
      let tot = 0;
      for (var i = 0; i < matrix.length; i++) {
        tot += parseFloat(matrix[i][col] ? matrix[i][col] : 0);
      }
      return tot;
    },

    async cargarFacturacion() {
      await axios({
        method: "POST",
        url: "/api/tep/liquidacionesNacionales/cargarFacturacion",
        data: this.form,
      })
        .then(async (response) => {
          this.detalleFacturacion = response.data;
        })
        .catch(async (e) => {});
    },

    // Función para calcular los días transcurridos entre dos fechas
    restaFechas(f1, f2) {
      let dif = this.getFecha(f2) - this.getFecha(f1) + 1;
      let dias = Math.ceil(dif / (1000 * 60 * 60 * 24));
      return dias;
    },

    getFecha(fecha) {
      const date = fecha.split(" ");
      let new_fecha = date[0].split("-");
      return Date.UTC(new_fecha[0], new_fecha[1] - 1, new_fecha[2]);
    },

    selectContrato() {
      this.form.contratos_id = [];
      if (this.contratos) {
        this.form.contratos_id = this.contratos.map((contrato) => contrato.id);
      }
    },

    back() {
      return this.$router.replace("/Tep/LiquidacionesNacionales");
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    await this.getResponsable();
    this.cargando = false;
  },
};
</script>
